const firebaseConfig = {
  apiKey: 'AIzaSyAJYnU4TDanb61sXVrEpILWe1krLwB1ImI',
  authDomain: 'thestablemanager.firebaseapp.com',
  databaseURL: 'https://thestablemanager.firebaseio.com',
  projectId: 'thestablemanager',
  storageBucket: 'thestablemanager.appspot.com',
  messagingSenderId: '370020283175',
  appId: '1:370020283175:web:dd6cde6bd6e39ed450ef02',
  measurementId: 'G-V8H1XK5D1F'
};

export default firebaseConfig;