<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
// Styles
import '@/styles/overrides.sass';

export default {
  name: 'StableManager',
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | StableManager',
    htmlAttrs: { lang: 'en' },
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    ],
  },
};
</script>
