const EN_LOCALE_ONLY = process.env.EN_LOCALE_ONLY === 'true';
const IN_BROWSER = typeof window !== 'undefined';
const IS_DEBUG = process.env.DEBUG === 'true';
const IS_PROD = process.env.NODE_ENV === 'production';

export {
  EN_LOCALE_ONLY,
  IN_BROWSER,
  IS_DEBUG,
  IS_PROD,
};
